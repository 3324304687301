<template>
    <div class="task-plan-list">
        <div class="page-tools-content" style="margin-bottom: 0px; border: none;">
            <div class="left flex">
                <el-button plain round icon="el-icon-refresh-left" size="small" class="margin-r-10" :loading="loading" @click="initList">{{$t('project.component_TaskPlanList_btn_1')}}</el-button>
                <el-select v-model="form.documentId" @change="filterSelectChange" clearable size="small" :placeholder="$t('project.component_TaskPlanList_select_placeholder_1')" style="width: 100px;">
                    <template v-for="(value, key) in fileOptions">
                        <el-option :value="key" :key="key" :label="value"></el-option>
                    </template>
                </el-select>
                <el-select v-model="form.type" @change="filterSelectChange" clearable size="small" :placeholder="$t('project.component_TaskPlanList_select_placeholder_2')" class="margin-l-10" style="width: 100px;">
                    <template v-for="(value, key) in typeOptions">
                        <el-option :value="key" :key="key" :label="value"></el-option>
                    </template>
                </el-select>
                <el-select v-model="form.targetLanguageCode" @change="filterSelectChange" clearable size="small" :placeholder="$t('project.component_TaskPlanList_select_placeholder_3')" class="margin-l-10" style="width: 140px;">
                    <template v-for="(value, key) in targetLanguageOptions">
                        <el-option :value="key" :key="key" :label="value"></el-option>
                    </template>
                </el-select>
                <el-select v-model="form.assigneeMemberId" @change="filterSelectChange" clearable size="small" :placeholder="$t('project.component_TaskPlanList_select_placeholder_4')" class="margin-l-10" style="width: 120px;">
                    <template v-for="(value, key) in ownerOptions">
                        <el-option :value="key" :key="key" :label="value"></el-option>
                    </template>
                </el-select>
                <el-select v-model="form.status" @change="filterSelectChange" clearable size="small" :placeholder="$t('project.component_TaskPlanList_select_placeholder_5')" class="margin-l-10" style="width: 100px;">
                    <template v-for="(value, key) in statusOptions">
                        <el-option :value="key" :key="key" :label="value"></el-option>
                    </template>
                </el-select>
               
            </div>
            <div class="right">
                <el-button size="small" plain round @click="batchAssignTask" v-permission="`project:detailRouter:assignTask`"><svg-icon name="ib-user-plus"></svg-icon> {{$t('project.component_TaskPlanList_btn_2')}}</el-button>
                <el-button size="small" plain round @click="batchSetDeadline" v-permission="`project:detailRouter:deadline`"><svg-icon name="ib-alarm"></svg-icon> {{$t('project.component_TaskPlanList_btn_3')}}</el-button>
            </div>
        </div>
        <el-alert type="info" center effect="dark" style="min-height: 40px" :closable="false">
            <template slot="title">
                <span class="fonts-14">{{$t('project.component_TaskPlanList_tips_1')}}</span>
            </template>
        </el-alert>
        <div class="task-plan-table">
            <el-table :data="list" @selection-change="handleSelectionChange" style="width: 100%;">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_1')" min-width="120">
                <template slot-scope="scope">
                    <div class="file-icon">
                        <svg-icon name="ib-task-trans" v-if="scope.row.taskType === 'TRANSLATION'"></svg-icon>
                        <svg-icon name="ib-task-review" v-if="scope.row.taskType === 'REVISION'"></svg-icon>
                        <svg-icon name="ib-task-qa" v-if="scope.row.taskType === 'QUALITY_CONTROL'"></svg-icon>
                    </div>
                    {{scope.row.taskTypeDesc}}
                    <span class="color-999" v-if="scope.row.returned">{{$t('project.component_TaskPlanList_tips_2')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_2')" min-width="110">
                <template slot-scope="scope">
                    {{scope.row.splitRange.startSentenceIndex}} - {{scope.row.splitRange.endSentenceIndex}} {{$t('project.component_TaskPlanList_tips_3')}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_3')" prop="taskName" min-width="220" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_4')" min-width="90">
                <template slot-scope="scope">
                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_5')" min-width="170">
                <template slot-scope="scope">
                    <span v-if="scope.row.startTime">{{getTimeText(scope.row.startTime)}}</span>
                    <span class="color-999" v-else>{{$t('project.component_TaskPlanList_tips_4')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_6')" min-width="170" class-name="eidt-col">
                <template slot-scope="scope">
                    <div class="height-full flex flex-align-center" >
                        <svg-icon name="ib-alarm" className="fonts-18 color-999 margin-r-10"></svg-icon>
                        <template v-if="!$checkPermission('project:detailRouter:deadline')">
                            <span v-if="scope.row.deadlineTime">{{getTimeText(scope.row.deadlineTime)}}</span>
                            <span v-else class="color-999">{{$t('project.component_TaskPlanList_tips_5')}}</span>
                        </template> <!-- format="yyyy-MM-dd HH:mm" -->
                        <el-date-picker popper-class="hide-time" v-else
                            v-model="scope.row.deadlineTime"
                            type="datetime"
                            :editable="false"
                            :clearable="false"
                            :placeholder="$t('project.component_TaskPlanList_input_placeholder_1')"
                            prefix-icon="null"
                            default-time="12:00:00"
                            format="MM-dd HH:mm"
                            :picker-options="pickerOptions"
                            @change="val => setDeadLineTime(val, [scope.$index, 0], [scope.row.taskId])" style="width: 100%;">
                        </el-date-picker>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_7')" prop="taskName" min-width="170">
                <template slot-scope="scope">
                    <span :class="scope.row.status">{{scope.row.statusDesc}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_8')" prop="taskName" min-width="170">
                <template slot-scope="scope">
                    <template v-if="scope.row.assignee && scope.row.assignee.length">
                            <svg-icon name="ib-user-plus" className="fonts-18 color-999 margin-r-10"></svg-icon>
                            <el-tooltip :content="scope.row.assignee ? $t('project.component_TaskPlanList_tips_6') : $t('project.component_TaskPlanList_tips_7')" placement="top">
                                <span class="table-link cursor-pointer" @click="showAssignDialog(scope.row)">
                                    <template v-for="(assignee, assigneeIndex) in scope.row.assignee">
                                        <template v-if="assigneeIndex < 2">{{assignee.alias}}</template>
                                        <template v-if="assigneeIndex < scope.row.assignee.length - 1 && assigneeIndex < 1 ">,</template>
                                    </template>
                                    <span class="margin-l-5" v-if="scope.row.assignee.length > 2">{{$t('project.component_TaskPlanList_tips_8', {size: scope.row.assignee.length})}}</span>
                                </span>
                            </el-tooltip>
                    </template>
                    <span class="color-999" v-else>{{$t('project.component_TaskPlanList_tips_9')}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('project.component_TaskPlanList_table_column_9')" width="100">
                <template slot-scope="scope">
                    <template v-if="$checkPermission('project:detailRouter:assignTask')">
                        <el-tooltip :content="$t('project.component_TaskPlanList_tips_10')" placement="top">
                            <el-button type="text" class="button-text fonts-18" @click="queryMemberInfo(scope.row)"><svg-icon name="ib-ecard"></svg-icon></el-button>
                        </el-tooltip>
                    </template>
                </template>
            </el-table-column>
            </el-table>
        </div>
        
        <el-dialog :title="$t('project.component_TaskPlanList_dialog_1_title')" :visible.sync="contactDialog.show" destroy-on-close width="700px" top="20px">
            <div class="margin-b-20">
                <template v-for="(item, index) in contactDialog.user">
                    <el-row :gutter="10" :key="index">
                        <el-col :span="8">{{item.alias}}</el-col>
                        <el-col :span="8">
                            {{item.phone || '—'}}
                            <span class="margin-l-5 cursor-pointer" @click="copyText(item.phone)">
                                <svg-icon name="ib-copy"></svg-icon>
                            </span>
                        </el-col>
                        <el-col :span="8">
                            {{item.email || '—'}}
                            <span class="margin-l-5 cursor-pointer" @click="copyText(item.email)">
                                <svg-icon name="ib-copy"></svg-icon>
                            </span>
                        </el-col>
                    </el-row>
                </template>
            </div>
        </el-dialog>
        <el-dialog :title="$t('project.component_TaskPlanList_dialog_2_title')" :visible.sync="deadlineDialog.show" destroy-on-close width="420px" top="20px">
            <div class="deadline-dialog">
                <div class="flex flex-align-center">
                    <div class="task-type-icon">
                        <svg-icon name="ib-task-trans" v-if="deadlineDialog.task.taskType === 'TRANSLATION'"></svg-icon>
                        <svg-icon name="ib-task-review" v-if="deadlineDialog.task.taskType === 'REVISION'"></svg-icon>
                        <svg-icon name="ib-task-qa" v-if="deadlineDialog.task.taskType === 'QUALITY_CONTROL'"></svg-icon>
                    </div>
                    <div class="flex margin-x-10 fonts-15" style="max-width: 296px;">
                        <div class="text-omission">
                            {{deadlineDialog.task.taskName}}
                        </div>
                        <div class="flex-1 margin-l-10" style="min-width: 113px;">{{deadlineDialog.task.taskTypeDesc}} <template v-if="checkedList.length > 1">{{$t('project.component_TaskPlanList_tips_11')}}<span class="margin-x-5 color-warning">{{checkedList.length}}</span>{{$t('project.component_TaskPlanList_tips_12')}}</template></div>
                    </div>
                </div>
                <div class="flex flex-align-center margin-t-15">
                    <div class="task-type-icon">
                        <svg-icon name="ib-alarm"></svg-icon>
                    </div>
                    <div class="margin-l-10 flex-1">
                        <el-date-picker popper-class="hide-time" size="medium" v-model="deadlineDialog.deadlineTime"
                            type="datetime"
                            :editable="false"
                            :clearable="false"
                            :placeholder="$t('project.component_TaskPlanList_dialog_2_input_placeholder_1')"
                            prefix-icon="null"
                            default-time="12:00:00"
                            format="MM-dd HH:mm"
                            :picker-options="pickerOptions"
                            style="width: 100%;">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div slot="footer" class="text-center">
                <el-button plain round size="mini" @click="deadlineDialog.show = false">{{$t('project.component_TaskPlanList_dialog_2_btn_1')}}</el-button>
                <el-button type="primary" round size="mini" :loading="deadlineDialog.loading" @click="submitDeadlineEvent">{{$t('project.component_TaskPlanList_dialog_2_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <task-assign-dialog ref="taskAssignDialogDom" @change="assignChangeEvent"></task-assign-dialog>
    </div>
</template>
<script>
import moment from'moment';
import config from '@/utils/config';
import { projectApi, docApi, teamApi } from '@/utils/api';
import TaskAssignDialog from './TaskAssignDialog.vue';
import LanguageDirection from '@/components/LanguageDirection.vue'
export default {
    name: 'TaskPlanList',
    components: { TaskAssignDialog, LanguageDirection, },
    props: {
        doc: {
            type: Object,
            default: null,
        },
    },   
    data() {
        return {
            moment,
            config,
            loading: false,
            list: [],
            taskList: [],
            total: 0,
            checkedList: [],
            checkedTasks: [],
            isIndeterminate: false,
            allChecked: false,
            form: {
                documentId: '',
                assigneeMemberId: '',
                type: '',
                status: '',
                targetLanguageCode: '',
            },
            fileOptions: [],
            statusOptions: [],
            ownerOptions: [],
            typeOptions: [],
            targetLanguageOptions: {},
            pickerOptions: {
                disabledDate: time => {
                    let current = moment(time).format('yyyy-MM-DD');
                    let now = moment().format('yyyy-MM-DD');
                    return moment(now).diff(moment(current)) > 0;
                }
            },
            contactDialog: {
                show: false,
                user: [],
            },
            deadlineDialog: {
                show: false,
                task: {},
                deadlineTime: null,
                loading: false,
            }
        }
    },
    methods: {
        initList() {
            let url = `${projectApi.queryTaskPlanList}?projectId=${this.doc.projectId}`;
            if(this.form.documentId) {
                url = `${url}&documentId=${this.form.documentId}`;
            }
            if(this.form.type) {
                url = `${url}&type=${this.form.type}`;
            }
            if(this.form.assigneeMemberId) {
                url = `${url}&assigneeMemberId=${this.form.assigneeMemberId}`;
            }
            if(this.form.status) {
                url = `${url}&status=${this.form.status}`;
            }
            if(this.form.targetLanguageCode) {
                url = `${url}&targetLanguageCode=${this.form.targetLanguageCode}`;
            }
            this.loading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    let total = 0;
                    let taskList = [];
                    for(let key in res.data.documentTask) {
                        let _tasks = res.data.documentTask[key]; 
                        taskList = taskList.concat(_tasks);
                        total = total + _tasks.length;
                    }
                    this.list = taskList;
                    this.total = total;
                    this.fileOptions = res.data.filterOption.files;
                    this.statusOptions = res.data.filterOption.statuses;
                    this.ownerOptions = res.data.filterOption.assignees;
                    this.typeOptions = res.data.filterOption.types;
                    this.targetLanguageOptions = res.data.filterOption.targetLanguages;
                }
            }).finally(() => {
                this.loading = false;
                this.checkedList = [];
                this.isIndeterminate = false;
                this.allChecked = false;
            });
        },
        filterSelectChange(val) {
            this.initList();
        },
        getTimeText(t) {
            let tYear = moment(t).year();
            let cYear = moment().year();
            if(tYear === cYear) {
                return moment(t).format(`MM-DD HH:mm`);
            }else {
                return moment(t).format(`YYYY-MM-DD`);
            }
        },
        batchAssignTask() {
            if(this.checkedList.length === 0) {
                this.$message.warning(this.$t('project.component_TaskPlanList_method_batchAssignTask_message_1'));
                return;
            }
            console.log(this.checkedTasks);
            let task = this.checkedTasks[0];
            this.$refs.taskAssignDialogDom.setShow({task: this.checkedTasks, ids: this.checkedList, doc: this.doc}, true);
        },
        batchSetDeadline() {
            if(this.checkedList.length === 0) {
                this.$message.warning(this.$t('project.component_TaskPlanList_method_batchSetDeadline_message_1'));
                return;
            }
            let task = this.checkedTasks[0];
            this.deadlineDialog.task = task;
            this.deadlineDialog.show = true;
        },
        submitDeadlineEvent() {
            if (!this.deadlineDialog.deadlineTime) {
                this.$message.error(this.$t('project.component_TaskPlanList_method_submitDeadlineEvent_message_1'));
                return;
            }
            let deadline = moment(this.deadlineDialog.deadlineTime).format('yyyy-MM-DD HH:mm:ss');
            let url = docApi.batchSetAssignmentDeadline;
            let postData = {
                assignmentIdList: this.checkedList,
                deadline: deadline,
            };
            this.deadlineDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200) {
                    this.deadlineDialog.show = false;
                    this.initList();
                    console.log('[INFO] 批量设置截止日期 %s 成功', deadline);

                }
            }).finally(() => {
                this.deadlineDialog.loading = false;
            });
        },
        setDeadLineTime(val, [i, j], ids) {
            let deadline = moment(val).format('yyyy-MM-DD HH:mm:ss');
            let url = docApi.batchSetAssignmentDeadline;
            let postData = {
                assignmentIdList: ids,
                deadline: deadline,
            };
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200) {
                    console.log('[INFO] 设置截止日期 %s 成功', deadline)
                }
            }).catch(err => {
                this.list[i].deadlineTime = '';
            })
        },
        queryMemberInfo(row){
            console.log(row);
            this.contactDialog.user = row.assignee;
            this.contactDialog.show = true;
        },
        showAssignDialog(task){
            this.$refs.taskAssignDialogDom.setShow({task: [task], ids: [task.taskId], doc: this.doc}, true);
        },
        assignChangeEvent(param) {
            this.initList();
        },
        handleSelectionChange(selection) {
            console.log(selection);
            let ids = [];
            selection.map(item => {
                ids.push(item.taskId);
            });
            this.checkedList = ids;
            this.checkedTasks = selection;
        },
        checkAllEvent(val) {
            let _list = this.list;
            let _checkedList = [];
            if(val) {
                _list.map((item, i) => {
                    item.tasks.map((task, j) => {
                        task.checked = true;
                        _checkedList.push(task.taskId);
                    });
                });
            } else {
                _list.map((item, i) => {
                    item.tasks.map((task, j) => {
                        task.checked = false;
                    });
                });
                _checkedList = [];
            }
            this.list = _list;
            this.checkedList = _checkedList;
            this.$nextTick(() => {
                this.isIndeterminate = false;
                this.allChecked = this.checkedList.length === this.total;
            }); 
        },
        checkItemEvent(val, task) {
            if(val) {
                this.checkedList.push(task.taskId);
            }else {
                let index = this.checkedList.indexOf(task.taskId);
                this.checkedList.splice(index, 1);
            }
            this.$nextTick(() => {
                this.allChecked = this.checkedList.length === this.total;
                this.isIndeterminate = this.checkedList.length > 0 && this.checkedList.length < this.total;
            });
            
        },
        copyText(text){
            let self = this;
            this.$copyText(text).then(function (e) {
                self.$message.success(this.$t('project.component_TaskPlanList_method_copyText_message_1'));
            }, function (e) {
                self.$message.error(this.$t('project.component_TaskPlanList_method_copyText_message_2'));
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.task-plan-list {
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    .task-plan-table {
        table {
            width: 100%;
            table-layout: auto;
            thead {
                th {
                    font-size: 14px;
                    color: #999999;
                    text-align: left;
                    height: 43px;
                    line-height: 43px;
                    padding: 0 15px;
                    background-color: #FFFFFF;
                    border-bottom: 1px solid #e6e6e6;
                    box-sizing: border-box;
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 14px;
                        text-align: left;
                        min-height: 50px;
                        line-height: 1.5;
                        padding: 15px 15px;
                        background-color: #FFFFFF;
                        border-bottom: 1px solid #e6e6e6;
                        box-sizing: border-box;
                        vertical-align: middle;
                         &.no-padding-x {
                            padding-left: 0;
                            padding-right: 0;
                        }
                        &.no-padding-y {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        &.center {
                            text-align: center;
                        }
                        ::v-deep .el-button--text {
                            font-size: 14px;
                            svg {
                                font-size: 18px;
                            }
                        }
                    }
                    &.hover {
                        &:hover {
                            td {
                                background-color: #f5f5f5;
                            }
                        }
                    }
                }
            }
        }
        ::v-deep .el-table {
            td {
                padding: 10px 0;
                line-height: normal;
                vertical-align: middle;
                 &.eidt-col {
                    padding: 0px !important;
                    cursor: pointer;
                    /* &:hover {
                        background-color: #f8f8f8;
                    } */
                    .cell {
                        height: 40px;
                    }
                }
            }
        }
        ::v-deep .el-date-editor {
            .el-input__inner {
                padding: 0px;
                border: none;
                background-color: transparent;
                font-size: 14px;
                line-height: 21px;
                color: #000000;
                cursor: pointer;
                height: 100%;
            }
        }
        
        
        .ASSIGNED {
            color: #FF9900;
        }
        .REJECTED {
            color: #F56C6C;
        }
    }
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #f8f8f8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
.task-type-icon{
    width: 24px;
    height: 24px;
    border-radius: 50px;
    box-sizing: border-box;
    border: 1px solid #0000001A;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.deadline-dialog {
    ::v-deep .el-date-editor {
        .el-input__inner {
            padding-left: 20px;
            border-radius: 20px;
            font-size: 14px;
            color: #000000;
            cursor: pointer;
        }
    }
}
.contact-type-block{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F2F2F2;
    font-size: 20px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.el-picker-panel.hide-time {
    .el-button--text.el-picker-panel__link-btn {
        display: none;
    }
}
</style>

<template>
    <div class="file-analysis-component">
        <div class="flex flex-align-center flex-justify-between padding-y-12">
            <div class="flex">
                <div class="fonts-15 flex flex-align-center">
                    <span>{{$t('project.component_FileAnalysis_tips_1')}}</span>
                    <el-select v-model="targetLang" @change="selectTargetLangEvent" size="small" :placeholder="$t('project.component_FileAnalysis_select_placeholder_1')" style="width:120px;">
                        <template v-for="item in project.targetLangs">
                            <el-option :key="item.key" :value="item.key" :label="item.name"></el-option>
                        </template>
                    </el-select>
                    <!-- <span class="margin-l-40">当前文件总字数：{{'12345678'}}</span>
                    <span class="margin-l-40">重复字数：{{'12345678'}}</span> -->
                </div>
                <div class="fonts-15 flex flex-align-center margin-l-10">
                    <!-- <span>计价比率：</span> -->
                    <!-- <el-button plain round size="small" @click="settingDialog.show = true">锁定和计字设置</el-button> -->
                </div>
            </div>
            <div>
                <!-- <el-button plain round size="small">生成报告</el-button> -->
                <el-button round plain size="small" :loading="downloadLoading" @click="downloadFileAnalysis"><svg-icon name="ib-download" v-if="!downloadLoading"></svg-icon> {{$t('project.component_FileAnalysis_btn_1')}}</el-button>
            </div>
        </div>
        <template v-if="projectData">
            <div class="analysis-title">{{$t('project.component_FileAnalysis_tips_2')}}</div>
            <el-table :data="projectData.details" style="width: 100%;">
                <el-table-column label="">
                    <template slot-scope="scope">
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_3')" placement="right" v-if="scope.row.matchType === 'NEW'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_4')" placement="right" v-else-if="scope.row.matchType === 'MATCH_101'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_5')" placement="right" v-else-if="scope.row.matchType === 'MATCH_102'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <span v-else>{{scope.row.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_6')" placement="top">
                            <span>{{$t('project.component_FileAnalysis_tips_7')}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.percentage}}%</template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_8')" placement="top">
                            <span>{{$t('project.component_FileAnalysis_tips_9')}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.characters}}</template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_10')" placement="top">
                            <span>{{$t('project.component_FileAnalysis_tips_11')}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.words}}</template>
                </el-table-column>
                <el-table-column :label="$t('project.component_FileAnalysis_table_column_1')" prop="segments"></el-table-column>
                <el-table-column :label="$t('project.component_FileAnalysis_table_column_2')">
                    <template slot="header">
                        <el-tooltip :content="$t('project.component_FileAnalysis_tips_12')" placement="top">
                            <span>{{$t('project.component_FileAnalysis_tips_13')}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.ckWords}}</template>
                </el-table-column>
            </el-table>
        </template>
        <template v-if="docData?.length">
            <template v-for="item in docData">
                <div class="analysis-title" :key="`title-${item.documentId}`">{{item.documentName}}</div>
                <el-table :data="item.details" style="width: 100%;" :key="`table-${item.documentId}`">
                    <el-table-column label="">
                        <template slot-scope="scope">
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_3')" placement="right" v-if="scope.row.matchType === 'NEW'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_4')" placement="right" v-else-if="scope.row.matchType === 'MATCH_101'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_5')" placement="right" v-else-if="scope.row.matchType === 'MATCH_102'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <span v-else>{{scope.row.label}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_6')" placement="top">
                                <span>{{$t('project.component_FileAnalysis_tips_7')}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.percentage}}%</template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_8')" placement="top">
                                <span>{{$t('project.component_FileAnalysis_tips_9')}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.characters}}</template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_10')" placement="top">
                                <span>{{$t('project.component_FileAnalysis_tips_11')}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.words}}</template>
                    </el-table-column>
                    <el-table-column :label="$t('project.component_FileAnalysis_table_column_1')" prop="segments"></el-table-column>
                    <el-table-column :label="$t('project.component_FileAnalysis_table_column_2')">
                        <template slot="header">
                            <el-tooltip :content="$t('project.component_FileAnalysis_tips_12')" placement="top">
                                <span>{{$t('project.component_FileAnalysis_tips_13')}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.ckWords}}</template>
                    </el-table-column>
                </el-table>
            </template>
        </template>
    </div>
</template>
<script>
import { projectApi, commonApi } from '@/utils/api';
import config from'@/utils/config';
export default {
    props: {
        project: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            projectData: null,
            docData: [],
            downloadLoading: false,
            targetLang: '',
        }
    },

    methods: {
        initAnalysis() {
            let url = `${projectApi.queryDocAnalysis}?projectId=${this.project.projectId}&targetLang=${this.targetLang}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.projectData = res.data.projectData;
                    this.docData = res.data.docData;
                }
            })
        },
        selectTargetLangEvent(val){
            this.initAnalysis();
        },
        downloadFileAnalysis() {
            // let url = `${projectApi.downloadDocAnalysis}?projectId=${this.project.projectId}`;
            let url = commonApi.batchCreateDownloadTask;
            this.downloadLoading = true;
            this.$ajax.post(url, {
                title: this.$t('project.component_FileAnalysis_method_downloadFileAnalysis_title', {name: this.project.name}),
                metadataIdList: [this.project.projectId],
                metadataType: "TMS_PROJECT_ANALYSIS",
            }).then(res => {
                if(res.status === 200) {
                    this.downloadLoading = true;
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() => {
                this.downloadLoading = false;
            });
        },
        
    },
    mounted() {
        // this.initAnalysis();
        this.targetLang = this.project.targetLangs[0].key;
    },
    watch: {
        project(newVal) {
            this.targetLang = newVal.targetLangs[0].key;
        }
    }
}
</script>
<style lang="scss" scoped>
.file-analysis-component {
    margin-bottom: 40px;
    .analysis-title{
        height: 44px;
        background-color: #E9F8F2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }
}
</style>
